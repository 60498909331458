<template>
  <form
    class="user-form"
    @submit.prevent="submit"
  >

    <div class="form-group">
      <input
        id="username"
        class="form-control"
        v-model="user.username"
      />
      <label for="#username">Username</label>
    </div>

    <div class="form-group">
      <input
        id="name"
        class="form-control"
        v-model="user.name"
      />
      <label for="#name">Naam</label>
    </div>

    <div class="form-group">
      <input
        id="password"
        class="form-control"
        v-model="user.password"
      />
      <label for="#password">Wachtwoord</label>
    </div>

    <div class="form-group">
      <button
        type="submit"
        @click.prevent="submit"
      >Opslaan</button>
    </div>
  </form>
</template>

<script>
export default {
  props: ['user'],
  methods: {
    submit () {
      this.$emit('submit')
    }
  }
}
</script>

<style lang="scss" scoped>
h1 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 24px;
}

.form-control--no_underline {
  border: none;
}
</style>