<template>
  <div class="view-container">
    <UserForm
      :user="user"
      @submit="submitUser"
    />
  </div>
</template>

<script>
import UserForm from '../components/userForm'

import User from '../user.class'

export default {
  data () {
    return {
      user: {}
    }
  },
  components: {
    UserForm
  },
  methods: {
    submitUser () {
      if (!this.user.password) delete this.user.password

      this.$store.dispatch('updateUser', { ...this.user, id: this.userId })
    }
  },
  computed: {
    userId () {
      return this.$route.params.id
    }
  },
  async mounted () {
    if (this.userId) {
      const user = await this.$store.dispatch('getUserById', this.userId)

      if (user) {
        this.user = new User(user)
      }
    }
  }
}
</script>