export default class User {
  constructor({ id, created_at, updated_at, username, name, permission_level}) {
    this.id = id || null
    this.created_at = created_at || ''
    this.updated_at = updated_at || ''
    this.username = username || ''
    this.name = name || ''
    this.permission_level = permission_level || null
  }
}
